import React from 'react';
import { graphql } from 'gatsby';
import View from '../components/general-view';

const ReactGuideDocs = ({ data }) => {
    const docs = data.allMdx.nodes;
    return <View docs={docs} />;
};

export default ReactGuideDocs;

export const pageQuery = graphql`
    query {
        allMdx(
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { frontmatter: { published: { eq: true }, tags: { eq: "React" } } }
        ) {
            nodes {
                id
                excerpt(pruneLength: 110)
                frontmatter {
                    title
                    date
                    tags
                    imgsrc
                }
                fields {
                    slug
                }
            }
        }
    }
`;
